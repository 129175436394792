.rbc-time-view,
.rbc-month-view {
    background-color: white;
}

.rbc-header {
    padding: 4px;
}

.rbc-time-view .rbc-row {
    min-height: 27px;
}

.rbc-show-more {
    background-color: transparent;
    color: black;
    text-decoration: none;
}

.rbc-show-more:hover {
    text-decoration: underline;
}

.rbc-agenda-table tr {
    color: black !important;
    background-color: white !important;
    border: 1px solid #ddd !important;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    border-left: 1px solid #ddd;
    padding: 2px 10px;
}

.rbc-agenda-table td {
    background-color: white;
    font-size: 12px;
}

.rbc-event-content {
    height: 34px;
}

.rbc-event {
    border: 2px solid transparent;
    color: black;
    font-size: 0.75rem;
}

.rbc-day-bg.weekend,
.rbc-time-column.weekend {
    background-color: #e94f4f;
    opacity: 0.11;
}

.rbc-date-cell {
    min-height: 34px;
    padding-top: 4px;
}

.rbc-date-cell a {
    border-radius: 50%;
    background-color: transparent;
    padding: 2px;
    width: 26px;
    height: 26px;
    display: inline-block;
    text-align: center;
}

.rbc-date-cell a.weekend {
    background-color: #e94f4f;
    color: white;
}

.rbc-day-slot .rbc-event-label {
    line-height: 12px;
}
//@media print
//{
//  .MuiAppBar-root {
//    display: none !important;
//  }
//}
